import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'react-router-redux';
import config from '../config';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

class CancelTransactionButton extends Component {
    state = {
        disabled: false,
    };

    async setDisabled(disabled) {
        await this.setState({
            disabled: disabled,
        });
    }

    handleClick = () => {
        const { record, transaction_type, showNotification } = this.props;
        const token = localStorage.getItem('token');
        const headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });

        this.setDisabled(true)
            .then(() => {
                showNotification('Запрос отправлен');
                return fetch(
                    config[env].url + `admin/transactions_${transaction_type}/${record.id}/cancel`,
                    {
                        method: 'POST',
                        body: JSON.stringify(record),
                        headers,
                    }
                );
            })
            .then(async response => {
                const data = await response.json();
                if (response.status === 200) {
                    showNotification('OK');
                    await this.handleOk();
                } else {
                    await this.setDisabled(false);
                    showNotification(data.message, 'warning');
                }
            })
            .catch(e => {
                this.setDisabled(false).then();
                console.error(e);
                showNotification('Ошибка: что-то пошло не так', 'warning');
            });
    };
    disabled = () => {
        const { record, transaction_type } = this.props;
        switch (transaction_type) {
            case 'cp':
                return record && !['Authorized', 'Completed'].includes(record.status);
            case 'stripe':
                return record && !['requires_capture', 'succeeded'].includes(record.status);
            case 'payme':
                return record && !['held', 'payed'].includes(record.status_semantic);
            default:
                return true;
        }
    };
    handleOk = async () => {
        const { record, transaction_type } = this.props;
        this.setDisabled(true);
        switch (transaction_type) {
            case 'cp':
                record.status = 'Cancelled';
                break;
            case 'stripe':
                record.status = 'canceled';
                break;
            case 'payme':
                record.status_semantic = 'canceled';
                break;
        }
    };

    render() {
        return (
            <Button
                onClick={this.handleClick}
                variant="contained"
                color={'primary'}
                disabled={this.state.disabled || this.disabled()}
            >
                Отмена{' '}
            </Button>
        );
    }
}

CancelTransactionButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(CancelTransactionButton);
